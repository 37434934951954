import { Stack } from '@mui/material';
import { Skeleton } from '@mui/material';

import ButtonSkeleton from '../../components/Button/skeleton';
import Typography from '../../components/Typography';
import { Course } from './styles';

const DeleteCoursesDrawerSkeleton = () => {
  return (
    <Stack gap={5}>
      <Typography variant="h6Medium" color="grey.800">
        <Skeleton variant="text" width={300} />
      </Typography>
      <Stack gap={3}>
        {new Array(3).fill(<div />).map((_, index) => (
          <Course key={index}>
            <Stack gap={2}>
              <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                  <Typography variant="h6Medium" color="grey.800">
                    <Skeleton variant="text" width={100} />
                  </Typography>
                  <Typography variant="h6Medium" component="div" color="grey.400">
                    <Skeleton variant="text" width={200} />
                  </Typography>
                </Stack>
                <Typography variant="h6Regular" color="grey.800">
                  <Skeleton variant="text" width={150} />
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Stack direction="row" alignItems="center" gap={1} overflow="hidden">
                  <Skeleton variant="rounded" width={24} height={24} />
                  <Typography variant="paragraphRegular" color="grey.800" noWrap>
                    <Skeleton variant="text" width={200} />
                  </Typography>
                </Stack>
                <Typography variant="paragraphRegular" whiteSpace="nowrap">
                  <Skeleton variant="text" width={70} />
                </Typography>
              </Stack>
            </Stack>
            <ButtonSkeleton fullWidth />
          </Course>
        ))}
      </Stack>
    </Stack>
  );
};

export default DeleteCoursesDrawerSkeleton;
