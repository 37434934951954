import { FC } from 'react';

import {
  CssBaseline,
  GlobalStyles,
  GlobalStylesProps,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';

import PipContainerProvider from '../pip-container/Context';
import LayoutContextProvider from '../shared/Layout/Context';
import { useTheme } from '../styles/theme';
import BreadcrumbContextProvider from './BreadcrumbProvider';
import NotificationProvider from './NotificationProvider';
import StripeProviders from './StripeProvider';
import TourProvider from './TourProvider';

const globalStyles: GlobalStylesProps['styles'] = () => ({
  /** for styling inline svg icons in markdown */
  '.md-icon': {
    display: 'inline-flex',
    verticalAlign: 'middle',

    '&.baseline': {
      verticalAlign: 'baseline',
    },
  },
});

const GlobalProvider: FC = ({ children }) => {
  const theme = useTheme();

  return (
    <StripeProviders>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <LayoutContextProvider>
            <BreadcrumbContextProvider>
              <TourProvider>
                <PipContainerProvider>{children}</PipContainerProvider>
              </TourProvider>
            </BreadcrumbContextProvider>
            <NotificationProvider />
          </LayoutContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StripeProviders>
  );
};

export default GlobalProvider;
