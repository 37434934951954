import { useTranslation } from 'react-i18next';

import { Stack, useMediaQuery } from '@mui/material';

import Alert from '../../components/Alert';
import AlertButton from '../../components/AlertButton';
import Button from '../../components/Button';
import Drawer from '../../components/Drawer';
import Tag from '../../components/Tag';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import CalendarIcon from '../../icons/CalendarIcon';
import { format } from '../../utils/datetime';
import { forMobile } from '../../utils/media-queries';
import Skeleton from './Skeleton';
import { Course } from './styles';
import useDeleteCoursesDrawerVM, { Props as VMProps } from './vm';

const COURSE_DATE_FORMAT = 'MMM dd, yyyy';

interface Props extends VMProps {}

const DeleteCoursesDrawer = ({ open, onClose: handleClose }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY, i18nNS.COMMON]);
  const isMobile = useMediaQuery(forMobile);
  const { isFetchingCourses, courses, handleDeleteCourse } = useDeleteCoursesDrawerVM({
    open,
    onClose: handleClose,
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      header={t('delete_courses', { ns: i18nNS.COURSES })}
      footer={
        !isMobile && (
          <Button variant="text" onClick={handleClose}>
            {t('close', { ns: i18nNS.GLOSSARY })}
          </Button>
        )
      }
    >
      {isFetchingCourses ? (
        <Skeleton />
      ) : (
        <Stack gap={5}>
          <Typography variant="h6Medium" color="grey.800">
            {t('courses_where_you_are_an_admin', { ns: i18nNS.COURSES })}
          </Typography>
          <Stack gap={3}>
            {courses.map((course) => (
              <Course key={course._id}>
                <Stack gap={2}>
                  <Stack gap={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                      <Typography variant="h6Medium" color="grey.800" noWrap>
                        {course.details.code}
                      </Typography>
                      <Typography variant="h6Medium" component="span" color="grey.400" whiteSpace="nowrap">
                        {t('total_N_activities', { ns: i18nNS.COURSES, count: course.totalActivities })}
                      </Typography>
                    </Stack>
                    <Typography variant="h6Regular" color="grey.800">
                      {course.details.title}
                    </Typography>
                  </Stack>
                  {course.status.courseLive ? (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                      <Stack direction="row" alignItems="center" gap={1} overflow="hidden">
                        <CalendarIcon color="primary" />
                        <Typography variant="paragraphRegular" color="grey.800" noWrap>
                          {`${format(course.dates?.startDate ?? 0, COURSE_DATE_FORMAT)} - ${format(
                            course.dates?.endDate ?? 0,
                            COURSE_DATE_FORMAT
                          )}` || '-'}
                        </Typography>
                      </Stack>
                      <Typography variant="paragraphRegular" whiteSpace="nowrap">
                        {t('N_students', { ns: i18nNS.COMMON, count: course.students })}
                      </Typography>
                    </Stack>
                  ) : (
                    <Tag
                      color="warning"
                      label={t('unpublished', { ns: i18nNS.GLOSSARY })}
                      variant="outlined"
                    />
                  )}
                </Stack>
                <AlertButton
                  color="error"
                  fullWidth
                  aria-label={t('click_to_delete_this_course', { ns: i18nNS.COURSES })}
                >
                  {t('delete', { ns: i18nNS.GLOSSARY })}
                  <Alert>
                    <Alert.Body>
                      {t('a_deleted_course_cannot_be_recovered_are_you_sure', { ns: i18nNS.COURSES })}
                    </Alert.Body>
                    <Alert.Footer>
                      <Alert.Action variant="text">{t('no', { ns: i18nNS.GLOSSARY })}</Alert.Action>
                      <Alert.Action onClick={handleDeleteCourse(course._id)}>
                        {t('yes', { ns: i18nNS.GLOSSARY })}
                      </Alert.Action>
                    </Alert.Footer>
                  </Alert>
                </AlertButton>
              </Course>
            ))}
          </Stack>
        </Stack>
      )}
    </Drawer>
  );
};

export default DeleteCoursesDrawer;
